import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";





const settings = {

    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 1,
    autoplay: true,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 3
            }
        },
        {
            breakpoint: 480,
            settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 1
            }
        }
    ]
};
function ExploreTemplates() {
    return (
        <section className="templates">
            <div className="container">
                <h2 className="text-center">Some Examples</h2>
                <p className="text-center">Here are some website created by using eezix.ai
                </p>
                <Slider {...settings} className="center">
                    <div><a href="https://pizzamario.in" target={"_blank"} rel="noreferrer"><img className="img-fluid" src="content/img/temp1.png" /></a></div>
                    <div><a href="https://greenlivebiopaper.com" target={"_blank"} rel="noreferrer"><img className="img-fluid" src="content/img/temp2.png" /></a></div>
                    <div><a href="https://cathotels.in" target={"_blank"} rel="noreferrer"><img className="img-fluid" src="content/img/temp3.png" /></a></div>
                    <div><a href="https://dramshareef.in/" target={"_blank"} rel="noreferrer"><img className="img-fluid" src="content/img/temp4.png" /></a></div>
                    <div><a href="https://astuterealtors.in/" target={"_blank"} rel="noreferrer"><img className="img-fluid" src="content/img/temp5.png" /></a></div>
                </Slider>
                <div className="text-center mt-5">
                    <a href="https://app.eezix.ai/" className="white_btn btn ">Build Yours Now</a>
                </div>
            </div>
        </section>
    );
}

export default ExploreTemplates;
