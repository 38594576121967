
import Pricing from './components/Sections/Pricing';
import Header from './components/Sections/Header'
import Reviews from './components/Sections/Reviews'
import ExploreTemplates from './components/Sections/ExploreTemplates'
function App() {
  return (
    <div>
      <Header />
      <section className="banner" style={{ background: "url(content/img/pattern.jpg)" }}>
        <div className="container">
          <h1>Worlds Eaziest Website Builder</h1>
          <p> Imagine a world where building a professional, visually stunning website is as effortless as entering your business name and type.</p>
          <a className="btn white_btn" href="https://app.eezix.ai/">Build Yours Now</a>
          <img className="img-fluid arrow" src="content/img/arrow.svg" />

          <img className="img-fluid mt-5" src="content/img/banner_image.png" />
        </div>
      </section>
      <section className="ai">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-md-5">
              <div className="flexed_center ">
                <h2>Revolutionizing Website Creation with Eezix</h2>
                <p>At Eezix, our mission is to transform how individuals and businesses create websites. We leverage the cutting-edge
                  capabilities of artificial intelligence, specifically our AI technology, to craft a groundbreaking product that simplifies
                  web development like never before. Get to know eezix, the world's easiest AI-powered website builder.
                </p>
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/tMlpZ1MPWVo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen title="How to build a website using eezix.ai?" className="d-lg-none d-md-none mt-2 mb-3">
                </iframe>
                <a className="black_btn btn mt-3" href="https://app.eezix.ai/">Build Yours Now</a>
              </div>
            </div>
            <div className="col-xl-7 d-lg-block d-none d-md-block col-md-7">
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/tMlpZ1MPWVo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen title="How to build a website using eezix.ai?">
              </iframe>
            </div>
          </div>
        </div>
      </section>
      <section className="why pb-0" id="why-eezix">
        <div className="container">
          <div className="row">
            <div className="col-xl-8">
              <h2>Why should I use eezix?</h2>
              <p className="pb-5 mb-3">If you want to build a website, you should try using EEZIX. It's different from other
                website builders
                because
                it's easier to use and can help you create a website more quickly.
              </p>
            </div>
          </div>
        </div>
        <hr className="m-0" />
        <div className="container">
          <div className="row">
            <div className="col-xl-4 br-1 col-md-4">
              <div className="item ps-0">
                <img className="img-fluid " src="content/img/ai.png" />
                <h4>AI Powered Content Writing
                </h4>
                <p>Our AI powered platform create content for your website. You don't have to worry about it. We will
                  automatically generate the content and will use it in your website.</p>
              </div>
            </div>
            <div className="col-xl-4 br-1 col-md-4">
              <div className="item">
                <img className="img-fluid " src="content/img/img.png" />
                <h4>Image Based on your Business Type
                </h4>
                <p>We will fetch the appropriate image for your website with the help of Unsplash. You can either use the
                  image from our platform or can upload yours.</p>
              </div>
            </div>
            <div className="col-xl-4 col-md-4">
              <div className="item pe-0">
                <img className="img-fluid " src="content/img/launch.png" />
                <h4>Ready to launch Website</h4>
                <p>You can build a website which you can instantly launch. Don’t have to wait for anything, The plans
                  includes
                  Hosting, Domain, Email, Free SSL and everything you need.</p>
              </div>
            </div>
          </div>
        </div>
        <hr className="m-0" />
        <div className="container">
          <div className="row">
            <div className="col-xl-4 br-1  col-md-4">
              <div className="item ps-0 mb-5">
                <img className="img-fluid " src="content/img/dash.png" />
                <h4>Easy to use Dashboard</h4>
                <p>EEZIX is very different platform from other website builders. A non tech person also able to create, edit
                  their website using its easy to use dashboard.</p>
              </div>
            </div>
            <div className="col-xl-4 br-1  col-md-4">
              <div className="item mb-5">
                <img className="img-fluid " src="content/img/app.png" />
                <h4>App to manage your Customers</h4>
                <p>You can save your customer details, enter the sales, make reports, overview your business etc. Either you
                  are an Offline seller or service provider you can easily build a database...</p>
              </div>
            </div>
            <div className="col-xl-4 col-md-4">
              <div className="item pe-0 mb-5">
                <img className="img-fluid " src="content/img/help.png" />
                <h4>24/7 Support</h4>
                <p>We provide 24/7 customer support. You can either contact via chat or email to clear your doubts and to
                  solve problems If there is any.</p>
              </div>
            </div>
          </div>
        </div>
        <hr className="m-0" />
      </section>
      <Pricing />
      <section className="dont_loose" style={{ background: "url(content/img/pattern.jpg)" }}>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-5">
              <img className="img-fluid" src="content/img/phone.png" />
            </div>
            <div className="col-xl-6 col-md-7">
              <div className="flexed_center">
                <h2>Don’t lose any Customers</h2>
                <p>A Free application to manage your customers with your website.
                </p>

                <ul>
                  <li>Get Direct enquiries from your website. </li>
                  <li>Track your customers easily. </li>
                  <li>Add customers manually. </li>
                  <li>Add sales.</li>

                </ul>
                <a href="https://app.eezix.ai/" className="white_btn btn">Build Yours Now</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="featurez">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-5">
              <div className="flexed_center">
                <h2>Ecommerce website features</h2>
                <p>Unlock the potential of effortless ecommerce website creation with Eezix. Our AI-powered platform simplifies the process,
                  allowing you to create a stunning online store in minutes. Say goodbye to complexity and hello to an intuitive solution that puts
                  you in control of your online business. Start your ecommerce journey with Eezix today.
                </p>
                <a className="black_btn btn mt-3 d-lg-block d-none" href="https://app.eezix.ai/">Build Yours Now</a>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7">
              <div className="row">
                <div className="col-xl-6 col-6">
                  <img className="img-fluid" src="content/img/f1.png" />
                  <h5>Ready to launch Website</h5>
                </div>
                <div className="col-xl-6 col-6">
                  <img className="img-fluid" src="content/img/f2.png" />
                  <h5>Manage the Orders easily</h5>
                </div>
                <div className="col-xl-6 col-6">
                  <img className="img-fluid" src="content/img/f3.png" />
                  <h5 className="pb-0">Manage your store on the Go</h5>
                </div>
                <div className="col-xl-6 col-6">
                  <img className="img-fluid" src="content/img/f4.png" />
                  <h5 className="pb-0">Sell Everywhere</h5>
                </div>
              </div>
              <a className="black_btn btn mt-3 d-lg-none" href="https://app.eezix.ai/">Build Yours Now</a>
            </div>

          </div>
        </div>
      </section>
      <ExploreTemplates />
      <section className="why_us">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-md-6">
              <h2>Why the Eezix website builder is the best choice for you</h2>
            </div>
            <div className="col-xl-7 col-md-6">
              <p className="mb-0">Our company, Aider Pro Technologies, was founded in 2013 as a website and mobile application.
                development company. We found that there are two types of users in this industry: those who are ready to
                invest a lot of money in website building, and those who need a website but can't spend a lot of money. We
                found an automated solution to build websites ourselves. Just try our platform to know the difference
                between current website builders and
                EEZIX.</p>
            </div>
          </div>
        </div>
      </section>
      {/* <Reviews /> */}
      <hr className="mt-0" />
      <section className="faq">
        <div className="container">
          <h2 className="mb-5">FAQs</h2>
          <h4>1.Can I customize the design of my website with Eezix.ai?</h4>
          <p>Absolutely! Eezix.ai offers a user-friendly interface that allows you to customize your website's design, colors, and layout effortlessly. You can make changes with just a single click to ensure your site reflects your brand identity.</p>
          <hr className="mt-5 mb-5" />
          <h4>2.Is Eezix.ai suitable for all types of businesses?</h4>
          <p>Yes, Eezix.ai is designed to cater to businesses of all sizes and types. Whether you're a small startup, a creative freelancer, or a well-established enterprise, our platform adapts to meet your specific needs.</p>
          <hr className="mt-5 mb-5" />
          <h4>3.Do I need technical skills to use Eezix.ai?</h4>
          <p>No technical skills are required! Eezix.ai is engineered to be incredibly user-friendly. If you can use a mobile device, you can create and manage your website with ease.</p>
          <hr className="mt-5 mb-5" />
          <h4>4.What ongoing support does Eezix.ai provide?</h4>
          <p>Eezix.ai is committed to your success. We offer comprehensive support to assist you in your online journey. From troubleshooting issues to helping you make the most of our platform, our support team is here to help you every step of the way.</p>
          <hr className="mt-5 mb-5" />
          <h4>5.How is Eezix able to offer websites at such affordable prices?</h4>
          <p>Eezix.ai leverages the power of artificial intelligence, to automate the website creation process. By significantly reducing the time and resources required for web development, we can offer cost-effective solutions to our users. Additionally, our commitment to simplicity and user-friendliness means you won't need to hire expensive web developers or invest in complex design software, further lowering the overall cost of creating and maintaining your website. We believe in making website creation accessible to everyone without compromising on quality.</p>
        </div>
      </section>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-md-6">
              <a href="#">
                <img width="110" className="white_logo" src="content/img/logo.svg" /></a>
              <p>AiderPro Technologies, the parent company of <a href="https://app.eezix.ai" >eezix.ai</a>, is at the forefront of innovation with its AI-powered website builder, known as the "Word's easiest website builder." Our mission is to empower businesses and individuals to effortlessly create stunning websites by harnessing the cutting-edge capabilities of artificial intelligence. With <a href="https://app.eezix.ai" >eezix.ai</a>, we've redefined the website-building experience, making it simple, efficient, and accessible to all. Join us on a journey of seamless digital transformation, where innovation meets user-friendliness, thanks to our groundbreaking AI technology.</p>
            </div>
            <div className="col-xl-2 col-md-3">
              <ul>
                <li><a href="https://app.eezix.ai/privacy" >Privacy Policy</a></li>
                <li><a href="https://app.eezix.ai/terms">Terms of Use</a></li>
                <li><a href="#">Contact Us</a></li>
                <li><a href="eezix.ai/sitemap.xml">Site Map</a></li>
              </ul>
            </div>
            <div className="col-xl-2 col-md-3">
              <ul>
                <li><a href="https://app.eezix.ai" >Website Builder</a></li>
              </ul>
            </div>
            <div className="col-xl-3"></div>
          </div>
          <hr className="mt-4 mb-5" />
          <div className="row">
            <div className="col-xl-6 col-7">
              <span>Copyright © 2023 Eezix</span>
            </div>
            <div className="col-xl-6 col-5">
              <ul className="social">
                <li><a href="https://www.facebook.com/eezix" target={"_blank"} rel="noreferrer"><i className="fab fa-facebook-f"></i></a></li>
                <li><a href="https://www.instagram.com/eezix.ai/" target={"_blank"} rel="noreferrer"><i className="fab fa-instagram"></i></a></li>
                <li><a href="https://www.youtube.com/@eezix.ai-freewebsitebuilder" target={"_blank"} rel="noreferrer"><i className="fab fa-youtube"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <a href={`https://api.whatsapp.com/send?phone=9562631234`} target={"_blank"} rel="noreferrer" className="to-up visible"><i className="fa fa-whatsapp" aria-hidden="true"></i></a>
    </div>
  );
}

export default App;
