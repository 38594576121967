import { useState, useEffect } from "react";

function Header() {
    const [isHeaderActive, setHeaderActive] = useState(false);
    const handleScroll = () => {
        const scrollValue = window.scrollY;
        if (scrollValue >= 100) {
            setHeaderActive(true);
        } else {
            setHeaderActive(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <header className={`light-header ${isHeaderActive ? 'active' : ''}`}>
            <nav className="navbar navbar-expand-lg">
                <div className="container">

                    <div className="zeynep">
                        <ul>
                            <li>
                                <a href="#why-eezix">Why Eezix</a>
                            </li>
                            <li>
                                <a href="#pricing">Pricing</a>
                            </li>
                            <li>
                                <a href="https://app.eezix.ai/">Login</a>
                            </li>
                            <li>
                                <a href="https://app.eezix.ai/">Build Yours Now</a>
                            </li>

                        </ul>
                    </div>

                    <div className="zeynep-overlay"></div>

                    <div className=" navbar-collapse ">
                        <a href="#">
                            <img width="110" className="white_logo" src="content/img/dark_logo.png" />
                            <img width="110" className="dark_logo" src="content/img/logo.svg" />
                        </a>
                        <div className="navbar-nav d-none d-lg-flex">
                            <a className="nav-item nav-link pl-lg-0 active" href="#why-eezix">Why Eezix</a>
                            <a className="nav-item nav-link pl-lg-0 active" href="#pricing">Pricing</a>
                            <a className="nav-item nav-link pl-lg-0 active" href="https://app.eezix.ai/">Login</a>
                            <a className="nav-item nav-link btn" href="https://app.eezix.ai/">Build Yours Now</a>
                        </div>
                        <button type="button" className="btn-open d-lg-none p-0">
                            <i className="fas fa-bars"></i>
                        </button>
                    </div>


                </div>
            </nav>
        </header>
    );
}

export default Header;
