import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const settings = {
    dots: true,
    infinite: false,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};
function Reviews() {
    return (
        <section className="review">
            <div className="container">
                <h2 className="text-center">Reviews</h2>
                <p className="text-center">Website templates that set you up for success. You can create your own template in just few
                    click</p>
                <Slider {...settings} >
                    <div style={{ display: "block" }}>
                        <span>It is a long established fact that a reader will be distracted by the readable content of a page when
                            looking at its layout. Various versions have evolved over the years, sometimes.</span>
                        <hr />
                        <img className="img-fluid" src="content/img/t1.png" />
                        <h6>Jessica Simpson</h6>
                        <small>Photographer</small>
                        <ul>
                            <li><i className="fas fa-star gold"></i></li>
                            <li><i className="fas fa-star gold"></i></li>
                            <li><i className="fas fa-star gold"></i></li>
                            <li><i className="fas fa-star gold"></i></li>
                            <li><i className="fas fa-star"></i></li>
                        </ul>
                    </div>
                    <div style={{ display: "block" }}>
                        <span>It is a long established fact that a reader will be distracted by the readable content of a page when
                            looking at its layout. Various versions have evolved over the years, sometimes.</span>
                        <hr />
                        <img className="img-fluid" src="content/img/t1.png" />
                        <h6>Jessica Simpson</h6>
                        <small>Photographer</small>
                        <ul>
                            <li><i className="fas fa-star gold"></i></li>
                            <li><i className="fas fa-star gold"></i></li>
                            <li><i className="fas fa-star gold"></i></li>
                            <li><i className="fas fa-star gold"></i></li>
                            <li><i className="fas fa-star"></i></li>
                        </ul>
                    </div>
                    <div style={{ display: "block" }}>
                        <span>It is a long established fact that a reader will be distracted by the readable content of a page when
                            looking at its layout. Various versions have evolved over the years, sometimes.</span>
                        <hr />
                        <img className="img-fluid" src="content/img/t1.png" />
                        <h6>Jessica Simpson</h6>
                        <small>Photographer</small>
                        <ul>
                            <li><i className="fas fa-star gold"></i></li>
                            <li><i className="fas fa-star gold"></i></li>
                            <li><i className="fas fa-star gold"></i></li>
                            <li><i className="fas fa-star gold"></i></li>
                            <li><i className="fas fa-star"></i></li>
                        </ul>
                    </div>
                    <div style={{ display: "block" }}>
                        <span>It is a long established fact that a reader will be distracted by the readable content of a page when
                            looking at its layout. Various versions have evolved over the years, sometimes.</span>
                        <hr />
                        <img className="img-fluid" src="content/img/t1.png" />
                        <h6>Jessica Simpson</h6>
                        <small>Photographer</small>
                        <ul>
                            <li><i className="fas fa-star gold"></i></li>
                            <li><i className="fas fa-star gold"></i></li>
                            <li><i className="fas fa-star gold"></i></li>
                            <li><i className="fas fa-star gold"></i></li>
                            <li><i className="fas fa-star"></i></li>
                        </ul>
                    </div>
                </Slider>

            </div>
        </section>
    );
}

export default Reviews;
