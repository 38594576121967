import { useState, useEffect } from "react";
import { fetchPricing } from '../../../api'

function Pricing() {
    const [servicePackages, setServicePackages] = useState([]);
    const [servicePlans, setServicePlans] = useState([]);
    const [ecommercePackages, setEcommercePackages] = useState([]);
    const [ecommercePlan, setECommercePlan] = useState([]);
    const [selectedFeature, setSelectedFeature] = useState(0);
    useEffect(() => {
        fetchPriceData();
    }, [])
    const filterPlans = (convertedArr) => {
        const arr = [];
        convertedArr.forEach((pack) => {
            pack.description.forEach((desc) => {
                const index = arr.findIndex((val) => val.keyword === desc['keyword']);
                const obj = {};
                if (index === -1) {
                    obj.title = desc['title'];
                    obj.keyword = desc['keyword'];
                    obj.subTitle = desc['subtitle'];
                    obj.plans = [desc.value];
                    arr.push(obj);
                } else {
                    arr[index].plans.push(desc.value);
                }
            })
        });
        return arr;
    }
    const fetchPriceData = async () => {
        const packages = await fetchPricing();
        const servicePlans = packages.data.filter((arr) => arr.website_type == 1);
        const ecommercePackages = packages.data.filter((arr) => arr.website_type == 2);
        const convertedArr = servicePlans.map((pack) => ({ ...pack, description: JSON.parse(pack.description) }));
        const ecommerceConverted = ecommercePackages.map((pack) => ({ ...pack, description: JSON.parse(pack.description) }));
        const sPlans = filterPlans(convertedArr);
        const ePlans = filterPlans(ecommerceConverted);
        setEcommercePackages(ecommerceConverted);
        setECommercePlan(ePlans);
        setServicePackages(convertedArr);
        setServicePlans(sPlans);
        // setECommercePlan(ecommerce);
    }

    const checkIfBoolean = (val) => {
        return typeof (val) !== 'boolean';
    }

    const onToggleList = (e, id) => {
        e.preventDefault();
        setSelectedFeature(id);
    }
    return (
        <section className="pricing" id="pricing" >
            <div className="container">
                <div className="edit-wrapper edit-or-customize ">
                    <div className="row">
                        <div className="col-lg-3 col-xl-2"></div>
                        <div className="col-lg-6 col-xl-8">
                            <h2 className="text-center ">How much it cost?
                            </h2>
                            <p className="text-center mb-5"> You need a website for your business, but you shouldn't have to pay more than you need to. You can get a great website for a reasonable price. 1599/year</p>
                        </div>
                        <div className="col-lg-3 col-xl-2"></div>
                        <div className="col-xl-4 col-md-2 col-lg-2"></div>
                        <div className="col-xl-4 col-md-8 col-lg-8">
                            <ul className="nav nav-pills mb-5 nav-justified" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
                                        type="button" role="tab" aria-controls="pills-home" aria-selected="true">Service Website</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
                                        type="button" role="tab" aria-controls="pills-profile" aria-selected="false">eCommerce</button>
                                </li>

                            </ul>
                        </div>
                        <div className="col-xl-4 col-md-2 col-lg-2"></div>
                        <div className="col-xl-12">



                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade active show" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <div className="responsive d-lg-none d-md-none">
                                        {servicePackages.map((service, index) => <div className="card p-2" key={index}>
                                            <div className="card-body">
                                                <h6>{service.name}</h6>
                                                <h4 className="panel-title price"><b>{service.price}</b> <small>/ Year</small></h4>
                                                {/* <span>(1499) (83/Month) (Including tax)</span> */}
                                                <hr />
                                                <span className={`show-fatures ${selectedFeature === service.id ? 'active' : ''}`} href="#" onClick={(e) => onToggleList(e, service.id)}></span>
                                                {service.description.map((desc, index) => <ul key={index} className={`features ${selectedFeature === service.id ? 'active' : ''}`}>
                                                    {(desc.value && !checkIfBoolean(desc.value)) && (<li>{desc.title}</li>)}
                                                    {(checkIfBoolean(desc.value) && desc.value != 'false') && (<li> {desc.title} - {desc.value}<br />{desc.subTitle}</li>)}

                                                </ul>)}
                                                <a className="black_btn btn" href="https://app.eezix.ai/">Select Now</a>

                                            </div>
                                        </div>)}
                                    </div>
                                    <div className="d-none d-lg-block d-md-block">
                                        <table className="table table-hover table-bordered"
                                            style={{ textAlign: "center", paddingLeft: 200, paddingRight: 200 }}>
                                            <thead>
                                                <tr className="active">
                                                    <th style={{ background: "#fff" }}>
                                                        <center></center>
                                                    </th>

                                                    {servicePackages.map((service, index) => <th key={index}>
                                                        <center>
                                                            <h6>{service.name}</h6>
                                                        </center>
                                                    </th>
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: "left", paddingLeft: 15 }}>Amount</td>
                                                    {servicePackages.map((service, index) => <td key={index}>
                                                        <h4 className="panel-title price"><b>{service.price}</b> <small>/ Year</small></h4>
                                                        {/* <span>(1499) (83/Month) (Including tax )
                                                        </span> */}
                                                    </td>)}

                                                </tr>
                                                <tr>
                                                    <td colSpan="5" align="left" style={{ background: "#eeeeeec4", paddingLeft: 15 }} className="active">
                                                        <b>Features</b>
                                                    </td>
                                                </tr>
                                                {servicePlans.map((service, index) => <tr key={index}>
                                                    <td style={{ textAlign: "left", paddingLeft: 15 }}>{service.title}<br />{service.subTitle}</td>
                                                    {service.plans.map((plan, index) => <td key={index}>{(plan && !checkIfBoolean(plan)) && <i style={{ color: "limegreen" }} className="fa fa-check" aria-hidden="true"></i>}
                                                        {(!plan || plan == 'false') && <i style={{ color: "red" }} className="fa fa-times" aria-hidden="true"></i>}
                                                        {(checkIfBoolean(plan) && plan != 'false') && <span>{plan}</span>}</td>)}

                                                </tr>)}

                                                <tr>
                                                    <td></td>
                                                    <td> <a className="black_btn btn mt-2 mb-2" href="https://app.eezix.ai/">Select Now</a></td>
                                                    <td> <a className="black_btn btn mt-2 mb-2" href="https://app.eezix.ai/">Select Now</a></td>
                                                    <td> <a className="black_btn btn mt-2 mb-2" href="https://app.eezix.ai/">Select Now</a></td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                    <div className="responsive d-lg-none d-md-none">
                                        {ecommercePackages.map((service, index) => <div className="card p-2" key={index}>
                                            <div className="card-body">
                                                <h6>{service.name}</h6>
                                                <h4 className="panel-title price"><b>{service.price}</b> <small>/ Year</small></h4>
                                                {/* <span>(1499) (83/Month) (Including tax)</span> */}
                                                <hr />
                                                <span className={`show-fatures ${selectedFeature === service.id ? 'active' : ''}`} href="#" onClick={(e) => onToggleList(e, service.id)}></span>
                                                {service.description.map((desc, index) => <ul key={index} className={`features ${selectedFeature === service.id ? 'active' : ''}`}>
                                                    {(desc.value && !checkIfBoolean(desc.value)) && (<li>{desc.title}</li>)}
                                                    {(checkIfBoolean(desc.value) && desc.value != 'false') && (<li> {desc.title} - {desc.value}<br />{desc.subTitle}</li>)}

                                                </ul>)}
                                                <a className="black_btn btn" href="https://app.eezix.ai/">Select Now</a>

                                            </div>
                                        </div>)}
                                    </div>
                                    <div className="d-none d-lg-block d-md-block">
                                        <table className="table table-hover table-bordered"
                                            style={{ textAlign: "center", paddingLeft: 200, paddingRight: 200 }}>
                                            <thead>
                                                <tr className="active">
                                                    <th style={{ background: "#fff" }}>
                                                        <center></center>
                                                    </th>
                                                    {ecommercePackages.map((service, index) => <th key={index}>
                                                        <center>
                                                            <h6>{service.name}</h6>
                                                        </center>
                                                    </th>
                                                    )}

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: "left", paddingLeft: 15 }}>Amount</td>
                                                    {ecommercePackages.map((service, index) => <td key={index}>
                                                        <h4 className="panel-title price"><b>{service.price}</b> <small>/ Year</small></h4>
                                                        {/* <span>(1499) (83/Month) (Including tax )
                                                        </span> */}
                                                    </td>)}

                                                </tr>
                                                <tr>
                                                    <td colSpan="5" align="left" style={{ background: "#eeeeeec4", paddingLeft: 15 }} className="active">
                                                        <b>Features</b>
                                                    </td>
                                                </tr>
                                                {ecommercePlan.map((service, index) => <tr key={index}>
                                                    <td style={{ textAlign: "left", paddingLeft: 15 }}>{service.title}<br />{service.subTitle}</td>
                                                    {service.plans.map((plan, index) => <td key={index}>{(plan && !checkIfBoolean(plan)) && <i style={{ color: "limegreen" }} className="fa fa-check" aria-hidden="true"></i>}
                                                        {(!plan || plan == 'false') && <i style={{ color: "red" }} className="fa fa-times" aria-hidden="true"></i>}
                                                        {(checkIfBoolean(plan) && plan != 'false') && <span>{plan}</span>}</td>)}

                                                </tr>)}


                                                <tr>
                                                    <td></td>
                                                    <td> <a className="black_btn btn mt-2 mb-2" href="https://app.eezix.ai/">Select Now</a></td>
                                                    <td> <a className="black_btn btn mt-2 mb-2" href="https://app.eezix.ai/">Select Now</a></td>
                                                    <td> <a className="black_btn btn mt-2 mb-2" href="https://app.eezix.ai/">Select Now</a></td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
}

export default Pricing;
